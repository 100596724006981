import React from 'react';
import Table from './recent.table';
import Navbar from '../../components/navbar';

function Recent() {
    return (
        <div className='w-10/12 dashboard--wraper pt-15 pl-15 pr-25 pb-15'>
            <Navbar />
            <Table />
        </div>
    );
}

export default Recent;
