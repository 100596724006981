import Grid from '@material-ui/core/Grid';
import React from "react";
export default function index(props){
    return(
        <React.Fragment>
            <Grid item={true} lg={3} md={4} sm={6} xs={12}>
                <div className={`stepcard statuscard`} style={{ height: 'auto' }}>
                    <div className="statuscard1">
                        <div>
                            <p className="font-extrabold" style={{fontSize:"15px"}}>{props.name}</p>
                            <p style={{fontSize:"11px",lineHeight:"1px"}}>{props.secondName} </p>
                        </div>
                  
                        <h2>{props.number}</h2>
                    </div>
                    <div className="statuscard2">
                        <img src={props.src} alt={props.name} />
                    </div>
                </div>
            </Grid>

        </React.Fragment>
    )
}
