import React from 'react';
import HearIcon from '../../components/HeartIcon';
import axios from 'axios';
import Cookies from 'js-cookie';
import TableCompoent from '../../components/table/index.js';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import './favourite.scss';
import requestForFavUnFav from '../../api/favAndUnfav';
import LoadingSkeleton from 'components/loadingSkeleton';
import Empty from 'components/empty';

const searchHead = ['Favorite', 'Date', 'Address'];
function Table() {
    const token = Cookies.get('token');
    const [favourites, setFavourites] = React.useState({
        data: [],
        meta: {},
    });
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const getRecentSearch = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/v1/general/address/favorites
            `,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setFavourites({ data: response.data.data });
            setLoading(false);
        };
        getRecentSearch();
    }, []);

    const changeFavourite = (propertyId) => {
        setFavourites((favourite) => {
            const fav = favourite.data;
            const findeIndex = fav.findIndex((data) => data.id === propertyId);
            fav.splice(findeIndex, 1);
            return {
                ...favourite,
                data: fav,
            };
        });
        requestForFavUnFav(propertyId);
    };

    if (loading === true) {
        return <LoadingSkeleton Head={searchHead} />;
    }

    return !!favourites.data.length ? (
        <TableCompoent Th={searchHead}>
            {favourites.data.map(({ created_at, address, id, favorite }) => {
                return (
                    <tr key={id}>
                        <td>
                            <HearIcon filled={favorite} changeFavourite={changeFavourite} id={id} />
                        </td>
                        <td>{dayjs(created_at).format('MMM D, YYYY')}</td>
                        <Link to={`/dashboard/details/${address}/view/`}>
                            <td>{address}</td>
                        </Link>
                    </tr>
                );
            })}
        </TableCompoent>
    ) : (
        <Empty />
    );
}

export default Table;
