import React from 'react'

function Dashboard() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" id="_002-chopping-board" width="19.165" height="22.102" data-name="002-chopping-board" viewBox="0 0 19.165 22.102">
            <path id="Path_1278" d="M19.165 2.489A2.489 2.489 0 0 0 16.676 0H2.489A2.489 2.489 0 0 0 0 2.489v17.124A2.489 2.489 0 0 0 2.489 22.1h14.187a2.489 2.489 0 0 0 2.489-2.489zm-1 17.124a1.493 1.493 0 0 1-1.493 1.493H2.489A1.493 1.493 0 0 1 1 19.613V2.489A1.493 1.493 0 0 1 2.489 1h14.187a1.493 1.493 0 0 1 1.493 1.493zm0 0" className="cls-1" data-name="Path 1278"/>
            <path id="Path_1279" d="M104.467 63h-5.476a1.991 1.991 0 0 0 0 3.982h5.476a1.991 1.991 0 1 0 0-3.982zm0 2.987h-5.476a1 1 0 1 1 0-1.991h5.476a1 1 0 1 1 0 1.991zm0 0" className="cls-1" data-name="Path 1279" transform="translate(-92.172 -59.864)"/>
            </svg>
        </div>
        
    )
}

export default Dashboard




