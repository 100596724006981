import React from 'react';
import Pakages from './Profile.Pakages';
const tableData = [
    { month: 'January', search: '232', billed: '34' },
    { month: 'February', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
    { month: 'January', search: '232', billed: '34' },
];

function ProfleBodySubscription() {
    return (
        <div className='profilebody--subscription'>
            <div className='profilebody--subscription--heading flex items-center h-92 px-40 mt-30'>
                <h3 className='f-p fs-24 fw-600'>Your Current Plan:</h3>
                <h5 className='ml-35 fw-500 fs-20'>Investor - ‘Pay Per Use’</h5>
            </div>
            <div className='flex -mx-15'>
                <div className='w-5/12 px-15'>
                    <table className='mt-13'>
                        <thead className=' fs-16 f-p normal fw-600'>
                            <tr className='h-40'>
                                <th className='w-45'></th>
                                <th className='text-left'>Month</th>
                                <th className='text-right'>Total Searches</th>
                                <th className='text-right'>Amount Billed</th>
                            </tr>
                        </thead>
                        <tbody className='f-p fs-16'>
                            {tableData.map((data, index) => {
                                return (
                                    <tr>
                                        <td className='text-center'>
                                            {index + 1}
                                        </td>
                                        <td className='text-left'>
                                            {data.month}
                                        </td>
                                        <td className='text-right'>
                                            {data.search}
                                        </td>
                                        <td className='text-right'>
                                            {data.billed}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='w-7/12 px-15'>
                    <Pakages />
                </div>
            </div>
        </div>
    );
}

export default ProfleBodySubscription;
