import React from 'react';

function Profile(props) {
    return (
        <div className='pl-40 pr-40 py-30 flex items-center pb-43'>
            <div className='w-90 h-90 rounded-full border-2 mr-51 overflow-hidden'>
                <img
                    style={{ width: '100%', height: '100%' }}
                    src={
                        !!props.user.profile_pic
                            ? props.user.profile_pic
                            : !!props.user.profile_avatar
                            ? props.user.profile_avatar
                            : 'https://carismartes.com.br/assets/global/images/avatars/avatar7_big@2x.png'
                    }
                    alt='Profile'
                />
            </div>
            <div className='profile--section'>
                <h5>{props.user.first_name}</h5>
                <h6 className='mb-5'>Investor</h6>
                <div className='flex profile--section--mobile'>
                    <div className='mr-29'>{props.user.email}</div>
                    <div>{props.user.phone}</div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
