import React from 'react';
import EditSVG from '../../assets/svg/edit.svg';
function Edit() {
    return (
        <button className='mr-5 outline-none'>
            <img className='h-28 w-28' src={EditSVG} alt='Edit' />
        </button>
    );
}

export default Edit;
