import React, { useState, useEffect } from 'react';
import Table from './batch.table';
import Navbar from '../../components/navbar';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Favourites() {
    const token = Cookies.get('token');
    let { id } = useParams();
    const [batchData, setBatchData] = useState([]);
    useEffect(() => {
        const getResponse = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/v1/general/batch/get-batch-property-list/${id}
            `,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setBatchData({ ...response.data });
        };
        getResponse();
    }, []);
    return (
        <div className='w-10/12 dashboard--wraper pt-15 pl-15 pr-25 pb-15'>
            <Navbar />
            <Table data={batchData.data} />
        </div>
    );
}

export default Favourites;
