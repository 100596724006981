import React, { useState } from 'react';
import SinginAndSingupWraper from '../../hoc/signupAndSIgnPage';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import useError from '../../hooks/useError';

// import EmailIcon from "../../assets/img/"
import './signin.css';

interface State {
    email: string;
    password: string;
    id_token: string;
}
const Signin: React.FC = () => {
    const history = useHistory();
    const [state, setState] = useState<State>({ email: '', password: '', id_token: '' });

    const { error, setError } = useError();

    const onChange = (event: any) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const showAndRemoveError = (time: number, errorMessage: string): void => {
        setError({
            isError: true,
            errorMessage: errorMessage,
        });
        setTimeout(() => {
            setError({
                isError: false,
                errorMessage: '',
            });
        }, time);
    };

    const onSubmit = async (event: any) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/signin`, {
                ...state,
                email: !!event.email ? event.email : state.email,
                id_token: !!event.id_token ? event.id_token : '',
                reg_by: !!event.loginType ? event.loginType : 'email',
            });

            if (response.status === 200) {
                Cookies.set('token', response.data.access_token, { expires: 30 });
                history.push('/dashboard');
            }
        } catch (error) {
            showAndRemoveError(4000, error.response.data.message);
        }
    };

    return (
        <div className='tntro-leftside pt-40 md:pt-92 pb-40 md:pb-92 pl-59 pr-59 mt-100'>
            <form className='w-full ' onSubmit={() => history.push('/dashboard')}>
                <div className='signin-input flex w-full h-55 pl-26 overflow-hidden mb-15'>
                    <div className='input--img'>
                        <img
                            className='w-20 h-20 mr-15'
                            src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/c8bda553-9c90-4075-883c-c29966d45a29.svg'
                            alt=''
                        />
                    </div>
                    <input
                        name='email'
                        onChange={onChange}
                        value={state.email}
                        className='w-full h-full outline-none'
                        type='text'
                        placeholder='Write your email'
                    />
                </div>
                <div className='signin-input flex w-full h-55 pl-26 overflow-hidden'>
                    <div className='input--img'>
                        <img
                            className='w-20 h-20 mr-15 '
                            src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/ce466f72-9b3b-44d3-bf2e-338937d5fc98.svg'
                            alt=''
                        />
                    </div>
                    <input
                        name='password'
                        onChange={onChange}
                        value={state.password}
                        className='w-full h-full outline-none'
                        type='password'
                        placeholder='Write your password'
                    />
                </div>
                <div onClick={onSubmit} className='signin--cehckbox mt-14'>
                    <button className='flex justify-center items-center'>Sign In</button>
                </div>
                {error.isError && (
                    <div className='fs-14 mb-14 mt-14' style={{ color: '#c71f66' }}>
                        {error.errorMessage}
                    </div>
                )}
            </form>
        </div>
    );
};

export default SinginAndSingupWraper(Signin);
