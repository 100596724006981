import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import React from 'react';
import { Link } from 'react-router-dom';
import './pagenation.scss';
const PaginationLink = ({ page, count, link }: any) => {
    return (
        <Pagination
            page={page}
            count={count}
            renderItem={(item) => <PaginationItem component={Link} to={link + '&' + 'page=' + item.page} {...item} />}
        />
    );
};

export default PaginationLink;
