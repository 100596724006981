import React from 'react';
import HearIcon from '../../components/HeartIcon';
import axios from 'axios';
import Cookies from 'js-cookie';
import TableCompoent from '../../components/table/index.js';
import requestForFavUnFav from '../../api/favAndUnfav';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import TableHeader from './recent.TableHeader';
import LoadingSkeleton from 'components/loadingSkeleton';
import Empty from 'components/empty';
const searchHead = ['Favorite', 'Date', 'Address'];

function Table() {
    const token = Cookies.get('token');
    const [reacentSearch, setSearch] = React.useState({
        data: [],
        meta: {},
    });
    const [loading, setLoading] = React.useState(true);
    // const [loading,setLoading] = React.useState(true);
    React.useEffect(() => {
        const getRecentSearch = async () => {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/recent-search?quantity=30`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setSearch({ ...response.data });
            setLoading(false);
        };

        getRecentSearch();
    }, []);
    const changeFavourite = (propertyId) => {
        setSearch((favourite) => {
            const favouriteArray = favourite.data.map((property) =>
                property.id === propertyId ? { ...property, favorite: !property.favorite } : property
            );

            return {
                ...favourite,
                data: favouriteArray,
            };
        });
        requestForFavUnFav(propertyId);
    };
    if (loading === true) {
        return (
            <div className='bg-white mt-20 table--top--wraper'>
                <LoadingSkeleton Head={searchHead} />
            </div>
        );
    }

    return (
        <div className='bg-white mt-20 table--top--wraper'>
            <TableHeader />
            {!!reacentSearch.data.length ? (
                <TableCompoent Th={searchHead}>
                    {reacentSearch.data.map(({ created_at, address, id, favorite }, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <HearIcon filled={favorite} changeFavourite={changeFavourite} id={id} />
                                </td>
                                <td>{dayjs(created_at).format('MMM D, YYYY')}</td>
                                <Link to={`/dashboard/details/${address}/view/`}>
                                    <td>{address}</td>
                                </Link>
                            </tr>
                        );
                    })}
                </TableCompoent>
            ) : (
                <Empty />
            )}
        </div>
    );
}

export default Table;
