import React from 'react';
import Logo from '../assets/img/logo.png';
import './style.css';
import BannerImg from '../assets/img/signup.png';
const SignupAndSIgnPage = (Component) => {
    return () => {
        return (
            <div>
                <div className='navbar' style={{ zIndex: '999999999999999' }}>
                    <div className='h-80 flex container'>
                        <a href='/'>
                            <img src={Logo} alt='logo' className='w-70 h-50' />
                        </a>
                    </div>
                </div>

                <div className='flex' style={{ zIndex: '0' }}>
                    <div className='startpage-left w-full sm:w-full md:w-8/12 lg:w-6/12 py-100 pl-10 md:pl-20 lg:pl-0 pr-10 md:pr-30 lg:pr-40 flex justify-center items-center'>
                        <div className='justify-center items-center flex'>
                            <Component />
                        </div>
                    </div>
                    <div
                        className='sm:w-0 md:w-4/12 lg:w-6/12 bg-black'
                        style={{
                            background: `url(${BannerImg}`,
                            backgroundSize: 'cover',
                            height: '100vh',
                        }}
                    ></div>
                </div>
            </div>
        );
    };
};

export default SignupAndSIgnPage;
