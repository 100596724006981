import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dropdownicon from '../../assets/img/dropdown-arrow.svg';
import Cookies from 'js-cookie';
import { useHistory, Link } from 'react-router-dom';
import './navbar.scss';
import { userState, setUser } from '../../reducers/user';
import { useDispatch, useSelector } from 'react-redux';

function Navbar() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [dropdown, setDropdown] = useState(false);
    const { first_name, profile_avatar, profile_pic } = useSelector(userState);

    const logout = () => {
        dispatch(
            setUser({
                active: null,
                address: null,
                dob: null,
                email: '',
                first_name: '',
                id: undefined,
                last_name: '',
                phone: '',
                photo: undefined,
            })
        );

        Cookies.remove('token');
        history.push('/signin');
    };
    return (
        <div className='navbarr'>
            <ClickAwayListener onClickAway={() => setDropdown(false)}>
                <div className='navbar--right--group' onClick={() => setDropdown(!dropdown)}>
                    <div className='navbar--right--profileimg' style={{ overflow: 'hidden', borderRadius: '50%' }}>
                        <img
                            style={{ borderRadius: '50%' }}
                            src={
                                !!profile_pic
                                    ? profile_pic
                                    : !!profile_avatar
                                    ? profile_avatar
                                    : 'https://carismartes.com.br/assets/global/images/avatars/avatar7_big@2x.png'
                            }
                            alt='User Imgae'
                        />
                    </div>
                    <div className='navbar--right--username'>{first_name}</div>

                    <div className='navbar--right--option'>
                        {/* event for show dropdown */}

                        <img src={Dropdownicon} alt='dropdown' />

                        {/* coondition check for show dropdown */}

                        {!!dropdown && (
                            <div className='navbar--right--option--dropdown'>
                                <Link to='/profile' exact={true}>
                                    <div className='navbar--right--option--dropdown--element'>Profile</div>
                                </Link>

                                <div onClick={logout} className='navbar--right--option--dropdown--element'>
                                    Logout
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
}

export default Navbar;
// https://carismartes.com.br/assets/global/images/avatars/avatar7_big@2x.png
