import axios from 'axios';
import Empty from 'components/empty';
import LoadingSkeleton from 'components/loadingSkeleton';
import PaginationLink from 'components/paginationLink';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import React from 'react';
// import { Link } from 'react-router-dom';
import TableCompoent from '../../components/table/index.js';
const searchHead = ['Name', 'Email', 'Member Since', 'Current Month', 'Lifetime', 'Interested In', 'Total Batch'];
function Table() {
    const query = new URLSearchParams(window.location.search);
    const page = parseInt(query.get('page') || '1', 10);
    const token = Cookies.get('token');
    const [favourites, setFavourites] = React.useState<any>({
        data: [],
        meta: {},
    });
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        setLoading(true);
        const getRecentSearch = async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/api/v1/admin/get-users?page=${page}&perpage=10
            `,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            setFavourites({ ...response.data });
            setLoading(false);
        };
        getRecentSearch();
    }, [page]);

    if (loading === true) {
        return <LoadingSkeleton Head={searchHead} />;
    }

    return !!favourites.data.length ? (
        <div>
            <TableCompoent Th={searchHead}>
                {favourites.data.map(
                    ({
                        first_name,
                        last_name,
                        email,
                        search_current_month,
                        total_property_search,
                        total_batch_upload,
                        member_since,
                        interested_in,
                        id,
                    }: any) => {
                        return (
                            <tr key={id}>
                                {/* <Link to={`/dashboard/${id}/view/`}> */}
                                <td>{first_name + ' ' + last_name}</td>
                                {/* </Link> */}
                                <td>{email}</td>
                                <td>{dayjs(member_since).format('MMM D, YYYY')}</td>
                                <td>{search_current_month}</td>
                                <td>{total_property_search}</td>
                                <td>{interested_in}</td>
                                <td>{total_batch_upload}</td>
                            </tr>
                        );
                    }
                )}
            </TableCompoent>
            {favourites.meta.last_page !== 1 ? (
                <div className='py-25 flex items-center justify-center'>
                    <PaginationLink page={page} count={favourites.meta.last_page} link='/dashboard?tab=search' />
                </div>
            ) : null}
        </div>
    ) : (
        <Empty />
    );
}

export default Table;
