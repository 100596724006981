import React from 'react';
import DeleteSVG from '../../assets/svg/delete.svg';
function Delete() {
    return (
        <button className='outline-none'>
            <img className='h-28 w-28' src={DeleteSVG} alt='Delete' />
        </button>
    );
}

export default Delete;
