import React from 'react';
import EmptyImg from '../../assets/img/empty.svg';
function Empty() {
    return (
        <div className='flex flex-col justify-center items-center' style={{ minHeight: '480px' }}>
            <img src={EmptyImg} alt='Empty here' className='mb-20' />
            <h3 className='fs-20'>It's empty here</h3>
        </div>
    );
}

export default Empty;
