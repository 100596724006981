import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

export default function CustomizedSnackbars(props: any) {
    return (
        <Snackbar open={props.open} autoHideDuration={5000} onClose={() => props.setError('')}>
            <div className='px-30 py-18 fs-16 font-semibold text-white' style={{ background: props.bg, borderRadius: '7px' }}>
                {props.message}
            </div>
        </Snackbar>
    );
}
