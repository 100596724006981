import React from 'react';
import Sidebar from '../../components/sidebar';
import Favourites from './favourites';

function index() {
    return (
        <div className='flex'>
            <Sidebar />
            <Favourites />
        </div>
    );
}

export default index;
