import { Grid } from '@material-ui/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import StatusCard from '../../components/statusCard';



function DashboardStatus() {
    const token = Cookies.get('token');
    const [state,setState] = React.useState({
        search_lifetime: 0,
search_this_month: 0,
total_comparables: 0,
total_users: 0
    })

    React.useEffect(()=>{
           const token = Cookies.get('token');
        const getDate =async ()=>{
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/admin/get-matrix`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setState({...response.data.data})
            }
             catch (e) {
                console.log(e)
            }       
        }
        getDate()
      
    },[])
    return (
        <div className='py-15'>
            <Grid container spacing={2}>
            <StatusCard
                    name='Total User'
                    secondName="(Lifetime)"
                    number={state.total_users}
                    src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/2055ac05-2085-4613-8902-282107d91318.svg'
                />
                <StatusCard
                    name= {"Properties Searched"} 
                    secondName="(Current Month)"
                    number={state.search_this_month}
                    src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/1e4b4f4a-ace1-4828-b9d3-7e112d0a398a.svg'
                />
                <StatusCard
                    name='Properties Searched'
                    secondName="(Lifetime)"
                    number={state.search_lifetime}
                    src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/4ace4810-7ff8-4e99-8c44-8b5975aac6a7.svg'
                />
                <StatusCard
                    name='Total Comparables'
                    secondName="(Lifetime)"
                    number={state.total_comparables}
                    src='https://cdn.zeplin.io/5ef9b0be7b4748819ae0decf/assets/fe3e1e39-a71b-4e80-9763-704d93d0da20.svg'
                />
             
            </Grid>
        </div>
    );
}

export default DashboardStatus;
