import React from 'react'

function TableHeader({title,HeaderRightComponent}) {

    return (
        
            <div className="h-92 table--header">
                <h4>{title}</h4>
                <div>
                    <HeaderRightComponent/>
                </div>
            </div>
        
    )
    
}

export default TableHeader
