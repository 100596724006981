import React from 'react';
import Sidebar from '../../components/sidebar';
import Estimator from './RepairEstimator';

function index() {
    return (
        <div className='flex'>
            <Sidebar />
            <Estimator />
        </div>
    );
}

export default index;
