import React from 'react';
import HearIcon from '../../components/HeartIcon';
import TableCompoent from '../../components/table/index.js';
import TableHeader from './batch.tableHeader';
import numberWithCommas from '../../utils/numberWithCommas';
const TableHead = ['Address', 'Beds', 'Baths', 'Estimate Value', 'Rent', 'STR Annual', 'Rental Cap', 'STR Cap'];
function Table({ data = [] }) {
    return (
        <div className='bg-white mt-20 table--top--wraper'>
            <TableHeader />
            <TableCompoent Th={TableHead}>
                {data.map((value, index) => {
                    return (
                        <tr key={index}>
                            <td>{value.address}</td>
                            <td className='text-center'>{value.bed}</td>
                            <td>{value.barth}</td>
                            <td>${numberWithCommas(value.est)}</td>
                            <td>${numberWithCommas(value.rent)}</td>
                            <td>${numberWithCommas(value.str_year)}</td>
                            <td>{value.rent_cpa == '00' ? 0 : value.rent_cpa.toFixed(2)}%</td>
                            <td>{value.str_cpa == '00' ? 0 : value.str_cpa.toFixed(2)}%</td>
                        </tr>
                    );
                })}
            </TableCompoent>
        </div>
    );
}

export default Table;
