import UploadingSnackbar from 'components/uploadingSnackbar';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'utils/AuthRouter';
import Dashboard from './pages/dashboard';
import Analyzer from './pages/dealAnalyzer';
import Favorite from './pages/favourites';
import Profile from './pages/profile';
import Recent from './pages/recent';
import Estimator from './pages/RepairEstimator';
//Metarial
// import AuthRoute from "./utils/AuthRoute";
//pages
import Signin from './pages/signin';
import SingleBatch from './pages/singleBatch';
import { csvState } from './reducers/csv';


function App() {
    const { csv } = useSelector(csvState);

    return (
        <BrowserRouter>
            <UploadingSnackbar csv={csv} />
            <Switch>
                {/* <Route path="/" exact component={Signin} /> */}
                <Route exact path='/' component={Signin} />

                <PrivateRoute>
                    <Switch>
                        <Route exact path='/dashboard' component={Dashboard} />
                        <Route exact path='/dashboard/batch/:id/view' component={SingleBatch} />
                        <Route path='/favourites' component={Favorite} />
                        <Route path='/recent' component={Recent} />
                        <Route path='/deal-analyzer' component={Analyzer} />
                        <Route path='/repair-estimator' component={Estimator} />
                        <Route path='/profile' component={Profile} />
                    
                    </Switch>
                </PrivateRoute>
            </Switch>
        </BrowserRouter>
    );
}

export default App;
