import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
const useStylesFacebook = makeStyles(() => ({
    root: {
        position: 'relative',
        color: '#50bfd8',
    },
}));

const isOpen = (csv: any) => {
    return !!Object.keys(csv).length;
};
function UploadingSnackbar({ csv }: { csv: any }) {
    const classes = useStylesFacebook();
    return (
        <Snackbar open={isOpen(csv)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <div className='download-snack'>
                <div>
                    <h3>Uploading Batch</h3>
                </div>

                {Object.keys(csv).map((data: any, id: any) => {
                    return (
                        <div key={id} className='flex justify-between items-center px-10 py-10' style={{ borderBottom: '1px solid #ddd' }}>
                            <div>
                                <p className='fs-14'>{data}</p>
                            </div>
                            <Box position='relative' display='inline-flex'>
                                <CircularProgress className={classes.root} />
                            </Box>
                        </div>
                    );
                })}
            </div>
        </Snackbar>
    );
}

export default UploadingSnackbar;
