import { createSlice } from '@reduxjs/toolkit';
export const csvSlice = createSlice({
    name: 'csv',
    initialState: {
        csv: {},
    },
    reducers: {
        setCsv: (state, action) => {
            return {
                csv: action.payload,
            };
        },
    },
});

export const { setCsv } = csvSlice.actions;
export const csvState = (state) => state.csvReducer;
export default csvSlice.reducer;
