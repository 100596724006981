import React from 'react';
import PropTypes from 'prop-types';

function Tab({ tabName, setTab }) {
    return (
        <div className='profile--tab flex'>
            <div
                className={tabName === 'details' ? 'profile--tab--action' : ''}
                onClick={() => setTab('details')}
            >
                Details
            </div>
            <div
                className={
                    tabName === 'subscription' ? 'profile--tab--action' : ''
                }
                onClick={() => setTab('subscription')}
            >
                Subscription
            </div>
        </div>
    );
}

Tab.propTypes = {
    tabName: PropTypes.oneOf(['details', 'subscription']).isRequired,
    setTab: PropTypes.func.isRequired,
};

export default Tab;
