import React from 'react';
import TableHeaderBar from '../../components/tableHeaderBar';
import { Button, ButtonSecondary } from '../../styles/Buttons.js';

function TableHeaderRightComponent() {
    return (
        <div>
            <ButtonSecondary>Create New</ButtonSecondary>
            <Button className='ml-24'>Edit Defaults</Button>
        </div>
    );
}

function TableHeader() {
    return (
        <div>
            <TableHeaderBar title='Deal Analyzer' HeaderRightComponent={TableHeaderRightComponent} />
        </div>
    );
}

export default TableHeader;
