import React from 'react';
import Table from './favourite.table';
import Navbar from '../../components/navbar';
import TableHeader from './favourite.tableHeader';
function Favourites() {
    return (
        <div className='w-10/12 dashboard--wraper pt-15 pl-15 pr-25 pb-15'>
            <Navbar />
            <div className='bg-white mt-20 table--top--wraper'>
                <TableHeader />
                <Table />
            </div>
        </div>
    );
}

export default Favourites;
