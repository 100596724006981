import React from 'react';
import SideBar from '../../components/sidebar';
import Profile from './Profile';
import './profile.scss';
function index() {
    return (
        <div className='flex'>
            <SideBar />
            <Profile />
        </div>
    );
}

export default index;
