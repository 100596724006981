import React, { useState } from 'react';
import BreadCumb from '../../../components/BreadCumb';
import { ContainerBox } from '../../../styles/Box';
import Details from './ProfileBody.Details';
import Profile from './ProfileBody.Profile';
import Tab from './ProfileBody.Tab';
import ProfleBodySubscription from './ProfleBody.Subscription';
import { userState } from '../../../reducers/user';
import { useSelector } from 'react-redux';
function ProfileBody() {
    const user = useSelector(userState);

    const [tab, setTab] = useState('details');

    return (
        <div>
            <BreadCumb breadCumb={[{ name: 'Profile', link: '/profile' }]} />
            <ContainerBox className='pl-21 pr-43'>
                <Profile user={user} />
                <Tab tabName={tab} setTab={setTab} />
                {tab === 'details' ? <Details user={user} /> : <ProfleBodySubscription />}
            </ContainerBox>
        </div>
    );
}

export default ProfileBody;
