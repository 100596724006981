import React from 'react';
import Navbar from '../../components/navbar';
import ProfileBody from './ProfileBody/index';
function Profile() {
    return (
        <div className='w-10/12 dashboard--wraper pt-15 pl-15 pr-25 pb-15'>
            <Navbar />
            <ProfileBody />
        </div>
    );
}

export default Profile;
