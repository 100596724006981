import React from 'react';
import Navbar from '../../components/navbar';
import Table from './RepairEstimator.table';
function Analyzer() {
    return (
        <div className='w-10/12 dashboard--wraper pt-15 pl-15 pr-25 pb-15'>
            <Navbar />
            <Table />
        </div>
    );
}

export default Analyzer;
