import React from 'react';
import Delete from '../../components/actionButton/Delete.js';
import Edit from '../../components/actionButton/Edit.js';
import TableCompoent from '../../components/table/index.js';
import range from '../../utils/arrayRange';
import Header from './RepairEstimator.Header';
const TableHead = ['Name', 'Property', 'Total Amount', 'Created', 'Action'];
function Table() {
    return (
        <div className='bg-white mt-20 table--top--wraper'>
            <Header />
            <TableCompoent Th={TableHead}>
                {range(1, 10).map(() => {
                    return (
                        <tr>
                            <td>717 S 125th Ave, Avondale, AZ 85323</td>
                            <td>30</td>
                            <td>20</td>
                            <td>20</td>
                            <td>
                                <Edit className='ml-40' />
                                <Delete />
                            </td>
                        </tr>
                    );
                })}
            </TableCompoent>
        </div>
    );
}

export default Table;
