import React from 'react';
import TableHeaderBar from '../../components/tableHeaderBar';
import { Button } from '../../styles/Buttons.js';

function TableHeaderRightComponent() {
    return (
        <div>
            <Button>Create New</Button>
        </div>
    );
}

function TableHeader() {
    return (
        <div>
            <TableHeaderBar title='Repair Estimator' HeaderRightComponent={TableHeaderRightComponent} />
        </div>
    );
}

export default TableHeader;
