import { configureStore } from '@reduxjs/toolkit';
import signlePropertyReducers from './singleProperty';
import userReducer from './user';
import csvReducer from './csv';
export default configureStore({
    reducer: {
        singleProperty: signlePropertyReducers,
        userReducer: userReducer,
        csvReducer: csvReducer,
    },
});
