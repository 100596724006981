import { useState } from 'react';

interface Error {
    isError: boolean;
    errorMessage: string;
}

export default () => {
    const [error, setError] = useState<Error>({
        isError: false,
        errorMessage: '',
    });

    return { error, setError };
};
