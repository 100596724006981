import React from 'react';
import Sidebar from '../../components/sidebar';
import Analyzer from './Analyzer';

function index() {
    return (
        <div className='flex'>
            <Sidebar />
            <Analyzer />
        </div>
    );
}

export default index;
