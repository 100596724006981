import React from 'react';
import Navbar from '../../components/navbar';
import TableHeaderBar from '../../components/tableHeaderBar';
import Status from './dashboard.status';
import Table from './dashboard.table';
export default function Dashboard() {
    return (
        <div className='w-10/12 dashboard--wraper pt-15 pl-15 pr-25 pb-15'>
            <Navbar />
            <Status />
            <div className='bg-white mt-20 table--top--wraper'>
                <TableHeaderBar title='All users' HeaderRightComponent={TableHeaderRightComponent} />
   
                <Table />
            </div>
        </div>
    );
}
function TableHeaderRightComponent() {
    return <span></span>;
}
