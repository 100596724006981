import axios from 'axios';
import Cookies from 'js-cookie';

const requestForFavUnFav = async (id) => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/address/favorite/${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get('token')}` },
    });
};

export default requestForFavUnFav;
