import React from 'react';
import Logo from '../../assets/img/logo.png';
import Dashboard from '../../assets/svg/Dashboard';
import Recent from '../../assets/svg/Recent';
import Analyzer from '../../assets/svg/Analyzer';
import Estimator from '../../assets/svg/Refair';
import Favorite from '../../assets/svg/Favourite';
import { NavLink } from 'react-router-dom';
import './sidebar.scss';
function index() {
    return (
        <div className='w-310  sidebar h-screen'>
            <a href='/'>
                <img src={Logo} className='mb-80 w-79 h-53 ml-40 mt-17' alt='logo' />
            </a>

            <NavLink activeClassName='is-active' to='/dashboard' className='h-52 w-full flex items-center pl-40'>
                <div className='h-52 flex items-center'>
                    <Dashboard />
                    Dashboard
                </div>
            </NavLink>
            {/* <NavLink activeClassName='is-active' to='/favourites' className='h-52 w-full flex items-center pl-40'>
                <div className='h-52 flex items-center'>
                    <Favorite />
                    Favorites
                </div>
            </NavLink>
            <NavLink activeClassName='is-active' to='/recent' className='h-52 w-full flex items-center pl-40'>
                <div className='h-52 flex items-center'>
                    <Recent />
                    Recent
                </div>
            </NavLink> */}
            {/* <NavLink activeClassName='is-active' to='/deal-analyzer' className='h-52 w-full flex items-center pl-40'>
                <div className='h-52 flex items-center'>
                    <Analyzer />
                    Deal Analyzer
                </div>
            </NavLink>
            <NavLink activeClassName='is-active' to='/repair-estimator' className='h-52 w-full flex items-center pl-40'>
                <div className='h-52 flex items-center'>
                    <Estimator />
                    Repair Estimator
                </div>
            </NavLink> */}
        </div>
    );
}

export default index;
