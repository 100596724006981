import React from 'react';
import TableHeaderBar from '../../components/tableHeaderBar';

function TableHeaderRightComponent() {
    return <button></button>;
}

function TableHeader() {
    return (
        <div>
            <TableHeaderBar title='Batch Name' HeaderRightComponent={TableHeaderRightComponent} />
        </div>
    );
}

export default TableHeader;
