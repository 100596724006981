import React, { useState } from 'react';
import styled from 'styled-components';
import IconFill from './heart-fill.svg';
import Icon from './heart.svg';

const Img = styled.img`
    margin: 0rem auto;
    cursor: pointer;
`;

function HeartIcon({ filled, changeFavourite, id }) {
    return <Img onClick={() => changeFavourite(id)} className='w-22 h-22' src={filled ? IconFill : Icon} alt='Fovourite' />;
}

export default HeartIcon;
