import React from 'react';

function Pakages() {
    const [pakages, setpakages] = React.useState('basic');
    return (
        <div className='pakages mt-10'>
            <div className='pakages--wraper'>
                <div className='pakages--tab h-54 fs-16 fw-500 f-p flex items-center'>
                    <div
                        onClick={() => setpakages('basic')}
                        className={`w-6/12 text-center ${pakages === 'basic' ? 'pakages--active' : ''}`}
                    >
                        Basic Pakage
                    </div>
                    <div
                        onClick={() => setpakages('volume')}
                        className={`w-6/12 text-center ${pakages === 'volume' ? 'pakages--active' : ''}`}
                    >
                        Volume Pakages
                    </div>
                </div>
                <div className='pakages--details f-p flex justify-between'>
                    <div>
                        <h5 className='normal fs-16 mb-24'>
                            This package is for High Volume or Nation-Wide Investors.
                        </h5>
                        <ul>
                            <li>500 properties included, $0.16 after</li>
                            <li>Detail Property Reports - $5/ property</li>
                        </ul>
                    </div>
                    <div className='flex flex-col text-center'>
                        <p className='fs-18' style={{ color: '#50bfd8' }}>
                            <span className='fs-14'>Start only</span>
                            <br />
                            $79.99/Month
                        </p>
                        <button className='text-white fs-16 fw-500'>Upgrade</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pakages;
