import React from 'react';
import Sidebar from "../../components/sidebar";
import Dashboard from "./dashboard";
import "./dashboard.scss";
// import Status from './dashboard.status';
function index() {
    return (
        <div className="flex">
            <Sidebar/>
          
            <Dashboard/>           
        </div>
    )
}

export default index
