import React from 'react';
import TableCompoent from '../../components/table/index.js';
import Skeleton from 'react-loading-skeleton';
import range from '../../utils/arrayRange';
function LoadingSkeleton(props: any) {
    return (
        <TableCompoent Th={props.Head}>
            {range(1, 10).map(() => {
                return (
                    <tr>
                        {props.Head.map((data: string) => {
                            return (
                                <td className={data == 'Favorite' ? 'w-50' : 'auto'}>
                                    <Skeleton />
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </TableCompoent>
    );
}

export default LoadingSkeleton;
