import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './breadcumb.scss';
function BreadCumb({ breadCumb }) {
    return (
        <div className='flex items-center pt-15 pb-15 breadcumb'>
            <div className='breadcumb--home '>
                <Link to='/dashboard'>Home</Link>
            </div>
            {!!breadCumb.length &&
                breadCumb.map((cumb, index) => {
                    return (
                        <div key={index}>
                            <Link to={cumb.link}>{cumb.name}</Link>
                            <span>&gt;</span>
                        </div>
                    );
                })}
        </div>
    );
}
BreadCumb.defaultProps = {
    breadCumb: [],
};

BreadCumb.propTypes = {
    breadCumb: PropTypes.array.isRequired,
};

export default BreadCumb;
